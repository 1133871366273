<template>
  <vca-card>
    <h2>{{ $t("email.new.header") }}</h2>
    <EmailAdd />
  </vca-card>
</template>

<script>
import EmailAdd from "@/components/account/emails/AccountAspEmailsAdd";
export default {
  name: "EmailsAdd",
  components: { EmailAdd },
  created() {
    this.$store.commit("user/aspevents/list", []);
    this.$store.dispatch({ type: "events/listAsp" }).then((response) => {
      const eventList =
        !response || response.length == 0
          ? []
          : response.filter((el) => {
              el.is_event_asp = true;
              return true;
            });
      this.$store.commit("user/aspevents/list", eventList);
      this.$store.commit("user/aspevents/pg/pageSize", eventList.length);
    });
  },
};
</script>
