<template>
  <div>
    <vca-column>
      <div v-if="getReceiverGroup">
        <vca-card class="shadowed">
          <AccountAspGroup v-model="create" ref="group" />
          <Email v-model="create" ref="email" />
        </vca-card>
        <vca-card class="shadowed">
          <Tips />
        </vca-card>
      </div>
    </vca-column>

    <button class="vca-button" @click="add()" :disabled="isInvalid">
      {{ $t("button.save") }}
    </button>
    <div class="vca-center">
      <vca-column class="vca-center">
        <button
          class="vca-button-small vca-center"
          :disabled="isInvalid"
          @click="preview = true"
        >
          {{ $t("button.preview") }} & {{ $t("button.send") }}
        </button>
      </vca-column>
    </div>

    <vca-popup
      v-if="preview"
      :show="preview"
      :title="$t('email.preview.title', { 0: create.subject })"
      @close="preview = false"
    >
      <EmailPreview :event="currentEvent" v-model="create" @send="send" />
    </vca-popup>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Tips from "@/components/emails/form/Tips";
import Email from "@/components/account/emails/form/AccountAspEmail";
import AccountAspGroup from "@/components/account/emails/form/AccountAspGroup";
import EmailPreview from "@/components/emails/EmailPreview";
export default {
  name: "EmailAdd",
  components: {
    Email,
    Tips,
    EmailPreview,
    AccountAspGroup,
  },
  data() {
    return {
      preview: false,
    };
  },
  created() {
    this.create.recipient_group.type = "event";
    this.create.from = this.user.crew.email;
    this.$store.commit("user/emails/mailbox/message/user_id", this.user.id);
  },
  methods: {
    add() {
      if (this.isInvalid) {
        this.$refs.receivers.validate();
        this.$refs.group.validate();
        this.$refs.email.validate();
      } else {
        this.$store
          .dispatch("user/emails/mailbox/message/create")
          .then((response) => {
            this.$store.commit("user/reset_message");
            this.$router.push({
              path: "/account/asps/emails/edit/" + response.id,
            });
          });
      }
    },
    send() {
      if (this.isInvalid) {
        this.preview = false;
        this.$refs.receivers.validate();
        this.$refs.group.validate();
        this.$refs.email.validate();
      } else {
        this.$store
          .dispatch("user/emails/mailbox/message/create")
          .then((response) => {
            this.$store.commit("user/reset_message");
            this.$store.commit("user/emails/mailbox/message/current", response);
            this.$store
              .dispatch("user/emails/mailbox/message/send")
              .then(() => {
                this.$store.dispatch("user/emails/mailbox/get").then(() => {
                  this.$router.push({
                    path: "/account/asps/emails",
                  });
                });
              });
          });
      }
    },
  },
  computed: {
    create: {
      set(value) {
        this.$store.commit("user/emails/mailbox/message/create", value);
      },
      get() {
        return this.$store.state.user.emails.mailbox.message.create;
      },
    },
    isInvalid() {
      return this.$v.create.$invalid;
    },
    getReceiverGroup() {
      return this.create.recipient_group.type;
    },
    currentEvent() {
      const event = this.getEvent(this.create.recipient_group.event_id);
      return event;
    },
    ...mapGetters({
      user: "user/current",
      getEvent: "user/aspevents/getEvent",
      validation: "user/emails/mailbox/message/validations",
      hasSystemPermission: "user/roles/hasSystemPermission",
    }),
  },
  validations() {
    return this.validation;
  },
};
</script>
