<template>
  <vca-field :label="$t('email.message.header')">
    <vca-row>
      <div class="short">
        {{ $t("email.message.subject.label", { 0: getCharCount }) }}
      </div>
      <div class="long">
        <vca-input
          v-model="value.subject"
          ref="subject"
          :maxLength="80"
          :rules="$v.value.subject"
          :errorMsg="$t('email.message.subject.errorMsg')"
          :placeholder="$t('email.message.subject.placeholder')"
        />
      </div>
    </vca-row>

    <vca-row>
      <div class="short">{{ $t("email.message.message.label") }}</div>
      <div class="long">
        <EmailEditor v-model="value" ref="editor" :rules="$v.value.message" />
      </div>
    </vca-row>

    <div class="vca-center">
      <vca-column class="vca-center">
        <button class="vca-button-small vca-center" @click="preview = true">
          {{ $t("button.preview") }}
        </button>
      </vca-column>
    </div>

    <vca-popup
      v-if="preview"
      :show="preview"
      :title="$t('email.preview.title', { 0: value.subject })"
      @close="preview = false"
    >
      <EmailPreview :event="currentEvent" v-model="value" :sendable="false" />
    </vca-popup>
  </vca-field>
</template>
<script>
import { mapGetters } from "vuex";
import EmailEditor from "@/components/emails/EmailEditor";
import EmailPreview from "@/components/emails/EmailPreview";
export default {
  name: "EmailsGeneral",
  props: {
    value: {
      type: Object,
      default: null,
    },
  },
  components: { EmailEditor, EmailPreview },
  data() {
    return {
      preview: false,
    };
  },
  computed: {
    getCharCount() {
      return this.value.subject ? 80 - this.value.subject.length : 80;
    },
    currentEvent() {
      const event = this.getEvent(this.value.recipient_group.event_id);
      return event;
    },
    ...mapGetters({
      getEvent: "user/aspevents/getEvent",
      validation: "emails/mailbox/message/validations",
    }),
  },
  validations() {
    return this.validation;
  },
  methods: {
    validate() {
      this.$refs.subject.validate();
      this.$refs.editor.validate();
    },
  },
};
</script>
